.search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 40px 15px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  &--active {
    visibility: unset;
    opacity: 1;
  }
}

.search__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  font-family: $second-font;
  color: #03183d;
}

.search__input {
  /* identical to box height, or 63% */
  display: block;
  width: 100%;
  max-width: 620px;
  margin-bottom: 29px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 30px;
  line-height: 19px;
  font-family: $second-font;

  /* текст темный заголоки */

  color: #03183d;

  letter-spacing: 0.05em;
  border: none;
  border-bottom: 2px solid #03183d;
  border-radius: 0;
  appearance: none;
}

.search__close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  appearance: none;

  svg {
    width: 22px;
    height: 22px;
  }
}

@media (min-width: 1000px) {
  .search {
    padding-top: 89px;
    padding-bottom: 77px;
  }

  .search__form {
    display: block;
    max-width: 620px;
    margin: 0 auto;
  }


  .search__text {
    display: block;
    max-width: 620px;
    margin: 0 auto;
  }

  .search__close {
    top: 38px;
    right: 114px;
  }
}
