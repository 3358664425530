.burger {
  width: 25px;
  height: 39px;
  padding: 0;
  font-size: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
}

.burger__inner {
  position: relative;
  display: block;
  height: 2px;
  background-color: #000000;
  transition: all 0.3s;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: inherit;
    background: #000000;
    transition: all 0.3s;
  }

  &::before {
    top: -7px;
  }

  &::after {
    bottom: -7px;
  }

  &--active {
    background-color: transparent;

    &::before {
      top: 0;
      transform: rotate(45deg);
      opacity: 0.3;
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
      opacity: 0.3;
    }
  }
}

@media (min-width: 1200px) {
  .burger {
    display: none;
  }
}
