// Шрифты
$first-font: "PTSans";
$second-font: "Gilroy";

// Цвета

// Градиенты

// Размеры

