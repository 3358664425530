.breadcrumbs__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
}

.breadcrumbs__item {
  font-size: 14px;
  line-height: 22px;
  color: #8e8e8e;
  transition: all 0.3s;

  &:not(:last-child) {
    display: flex;
    align-items: center;

    &:hover {
      color: #1d69c9;
    }

    &::after {
      @include pseudo;
      width: 6px;
      height: 9px;
      margin: 0 10px;
      background-image: url("../../img/seporator.svg");
    }
  }

  &:last-child {
    color: #38393a;
  }
}
