.gallery {
  width: 100%;
  margin-bottom: 70px;
  overflow: hidden;
}

.gallery__controls {
  position: absolute;
  top: 50%;
  left: 30px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
  transform: translateY(-50%);

  .controls__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 0;
    background: linear-gradient(90deg, #1c60c7 0%, #3380eb 100%);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 10px;
      height: 10px;
      fill: #ffffff;
    }
  }
}


.gallery__top {
  position: relative;
}

.gallery__list {
  @include list-reset;
}

.gallery__sub-list {
  @include list-reset;
  justify-content: center;
  margin-top: 40px !important;
}

.gallery__item {
  img {
    display: block;
    border-radius: 3px;
  }
}


.gallery__sub-item {
  border: 2px solid transparent;
  border-radius: 3px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: cover;
  }

  &.swiper-slide-thumb-active {
    border: 2px solid #1d69c9;
  }
}
