.slider {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232426;
  }
}

.slider__controls {
  display: flex;
  justify-content: center;
  margin-top: 28px;

  .controls__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 0;
    background: linear-gradient(90deg, #1c60c7 0%, #3380eb 100%);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s;

    &:first-child {
      margin-right: 13px;
    }

    &:hover {
      opacity: 0.6;
    }

    svg {
      width: 10px;
      height: 10px;
      fill: #ffffff;
    }
  }
}

.slider__list {
  @include list-reset;
}

.slider__item {
  img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .slider__controls {
    justify-content: flex-start;
  }
}
