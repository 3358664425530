.media-card {
  display: flex;
  flex-direction: column;
  min-height: 510px;
  padding: 30px;
  border: 1px solid #eaeaea;
  transition: all 0.3s;

  &:hover {
    border-color: #cbcbdd;
  }
}

.media-card__img {
  display: block;
  margin-top: -30px;
  margin-right: -30px;
  margin-bottom: 17px;
  margin-left: -30px;
}

.media-card__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-right: -5px;
  margin-bottom: 28px;
  margin-left: -5px;
}

.media-card__item {
  margin: 5px;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 16px;
  color: #2f80ed;
  text-transform: uppercase;
  background: #f2f4f7;
  border-radius: 3px;
}

.media-card__bottom {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-right: -30px;
  margin-bottom: -30px;
  margin-left: -30px;
  border-top: 1px solid #eaeaea;
}

.media-card__date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  font-family: $second-font;
  /* identical to box height, or 156% */


  color: #616161;
}

.media-card__button {
  width: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: $second-font;

  color: #ffffff;
  text-transform: uppercase;
  /* identical to box height */

  letter-spacing: 0.1em;
  background: linear-gradient(90deg, #1c60c7 0%, #3380eb 100%);
  border-radius: 0;

  &:hover {
    background: linear-gradient(90deg, #3480ea 0%, #1654b3 100%);
  }
}


.media-card__name {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  font-family: $second-font;

  color: #181818;
  /* or 132% */

  letter-spacing: 0.03em;
}
