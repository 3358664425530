.main {
  padding: 30px 15px 80px 15px;
}

.main__controls {
  position: relative;
}

.main__share-content {
  position: absolute;
  right: 17px;
  top: -35px;
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
}

.main__share-content--active {
  z-index: 1;
  opacity: 1;
}

.main__tags {
  @include list-reset;
  display: flex;
  margin-bottom: 52px;
  overflow: auto;
}

.main__search {
  display: none;
}

.main__categories {
  @include list-reset;
  display: flex;
  margin-bottom: 35px;
  overflow: auto;
  border-bottom: 1px solid #dde3f9;
}

.main__category {
  display: inline-block;
  padding-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #39393a;
  letter-spacing: 0.03em;
  /* identical to box height */
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &--active {
    color: #1d69c9;
    border-bottom: 1px solid #1d69c9;
  }
}

.main__list {
  @include list-reset;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  margin-bottom: 40px;
}

.main__title {
  margin-bottom: 25px;
  font-size: 30px;
  line-height: 40px;
  color: #323d54;
  text-transform: uppercase;

  & + .main__categories {
    margin-top: 47px;
  }
}

.main__desc {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  font-family: $second-font;
  color: #4b4b4b;
}

.main__callback {
  min-width: 222px;
  height: 71px;
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
}

.main__back {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  min-width: 191px;
  height: 47px;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #d1d1d1;
  border-radius: 3px;

  transition: all 0.3s;

  &:hover {
    color: #1d69c9;
  }

  &::before {
    @include pseudo;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    margin-right: 16px;
    background-image: url("../../img/prev.svg");
    background-size: 12px;
    border-right: 1px solid #d1d1d1;
  }
}

.main__share {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  height: 47px;
  margin-bottom: 30px;
  padding-left: 16px;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  transition: all 0.3s;

  &:hover {
    color: #1d69c9;
  }

  &::after {
    @include pseudo;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    margin-left: 16px;
    background-image: url("../../img/share.svg");
    background-size: 12px;
    border-left: 1px solid #d1d1d1;
  }
}


.main__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main__categories + .main__inner {
  margin-top: 50px;
}

.main__content {
  overflow: hidden;
}

.main__pagination + .main__callback {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .main {
    @include page-wrapper;
    padding-top: 90px;
    padding-bottom: 130px;
  }


  .main__pagination + .main__callback {
    margin-top: 67px;
  }

  .main__categories {
    &--inner {
      padding-top: 35px;
      padding-bottom: 22px;
      border-top: 1px solid #dde3f9;

      .main__category {
        padding-bottom: 10px;
        font-weight: 400;

        &--active {
          color: #39393a;
          border-bottom: 3px solid #1d69c9;
        }
      }
    }
  }

  .main__category {
    opacity: 1;
    transition: all 0.3s;

    &:hover {
      opacity: 0.6;
    }

    &:not(:last-child) {
      margin-right: 49px;
    }
  }

  .main__callback {
    margin-top: 67px;
  }

  .main__inner {
    display: flex;
    margin-top: 90px;
  }

  .main__sidebar {
    width: 300px;
    min-width: 300px;
    margin-right: 70px;
  }

  .main__tags {
    flex-wrap: wrap;
    max-width: 665px;
    margin-top: 5px;
    margin-right: -8px;
    margin-left: -8px;
    overflow: unset;
  }

  .main__top {
    display: flex;
    align-items: flex-start;
  }


  .main__search {
    display: flex;
    width: 19px;
    height: 19px;
    margin-top: 23px;
    margin-left: auto;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    appearance: none;

    svg {
      width: 100%;
      height: 100%;
      fill: #1d69c9;
    }
  }


  .main__desc {
    max-width: 830px;
    margin-bottom: 60px;
  }

  .main__list {
    margin-bottom: 90px;
    grid-template-columns: repeat(auto-fit, minmax(290px, 368px));
  }

  .main__title {
    font-size: 40px;
    line-height: 52px;
  }
}
