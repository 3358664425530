.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 20px;
  font-weight: 600;
  font-family: $second-font;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
  border: none;

  &--thin {
    font-weight: 400;
    font-size: 14px;
    font-family: $first-font;
    color: #1d69c9;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #1d69c9;

    &:hover {
      color: #ffffff;
      background-color: #1d69c9;
    }
  }

  &--accent {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-family: $second-font;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    background: linear-gradient(180deg, #4f84eb 0%, #2861cf 100%);
    border-radius: 3px;

    &:hover {
      background: linear-gradient(90deg, #3480ea 0%, #1654b3 100%);
    }
  }

  &--opacity {
    box-sizing: border-box;
    height: 50px;
    padding: 0 40px;
    padding-top: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #a2a6ab;
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #cbcbdd;
    border-radius: 3px;
    transition: all 0.3s;

    &:hover {
      color: #ffffff;
      background: #1d69c9;
      border-color: transparent;
    }
  }
}
