.pagination {
  @include list-reset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__item {
  width: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1d69c9;
  transition: all 0.3s;

  &:hover {
    color: #4f4f4f;
  }

  &--prev {
    width: auto;
    margin-right: 20px;

    svg {
      transition: all 0.3s;
      fill: #1d69c9;
    }

    &:hover {
      a {
        background-color: #1d69c9;

        svg {
          fill: #ffffff;
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: #ebeef1;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }

  &--next {
    width: auto;
    margin-left: 20px;

    svg {
      transition: all 0.3s;
      fill: #1d69c9;
    }

    &:hover {
      a {
        background-color: #1d69c9;

        svg {
          fill: #ffffff;
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: #ebeef1;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }
}
