.promo__list {
  @include list-reset;
}

.promo__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 400px;
  padding: 30px 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.promo__title {
  max-width: 738px;
  margin-bottom: 22px;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #2c2b2b;
  letter-spacing: 0.02em;
  text-shadow: 0 2px 4px #b6bbc0;
}

.promo__text {
  max-width: 375px;
  margin-bottom: 42px;
  font-size: 17px;
  line-height: 25px;
  color: #333333;
}

.promo__button {
  height: 60px;
  padding: 0 47px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.07em;
}

.promo__controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .promo__control {
    &:first-child {
      margin-right: 20px;
    }
  }
}

.promo__control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: #c7d9eb;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s;
  appearance: none;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 10px;
    height: 10px;
    fill: #1d69c9;
  }
}

@media (min-width: 600px) {
  .promo {
    position: relative;
  }

  .promo__controls {
    position: absolute;
    top: 50%;
    left: 15px;
    z-index: 10;
    justify-content: space-between;
    width: calc(100% - 30px);
    transform: translateY(-50%);
  }

  .promo__control {
    opacity: 0.8;
  }

  .promo__inner {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media (min-width: 1200px) {
  .promo__title {
    font-size: 55px;
    line-height: 65px;
  }

  .promo__inner {
    justify-content: flex-start;
    min-height: 680px;
    padding-top: 156px;
  }
}

@media (min-width: 1360px) {
  .promo__inner {
    @include page-wrapper;
  }
}

@media (min-width: 1460px) {
  .promo {
    position: relative;
  }

  .promo__controls {
    position: absolute;
    top: 50%;
    left: 75px;
    z-index: 10;
    justify-content: space-between;
    width: calc(100% - 150px);
    transform: translateY(-50%);
  }
}
