.controls__button {
  @include pseudo;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  padding: 0;
  background: transparent;
  border: 1.3px solid #91b0f6;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  appearance: none;

  svg {
    width: 20px;
    height: 20px;
    transition: all 0.3s;
    fill: #1d69c9;
  }

  &:hover {
    background: #1d69c9;
    border-color: #1d69c9;

    svg {
      fill: #ffffff;
    }
  }
}
