.services-card {
  position: relative;
  box-sizing: border-box;
  padding: 95px 35px 60px 35px;
  border: 1px solid #cbcbdd;
  border-radius: 3px;

  &:hover {
    border: 1px solid #1d69c9;

    .services-card__icon {
      background: linear-gradient(90deg, #1c60c7 0%, #3380eb 100%);

      svg {
        fill: #ffffff;
      }
    }
  }
}

.services-card__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 78px;
  background: #eff2f5;
  border-radius: 0 3px;

  svg {
    width: 45px;
    height: 50px;
    fill: #1d69c9;
  }
}

.services-card__name {
  display: inline-block;
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  font-family: $second-font;
  color: #4f4f4f;
  letter-spacing: 0.03em;
  transition: all 0.3s;

  &:hover {
    color: #1d69c9;
  }
}

.services-card__desc {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 23px;
  color: #939393;
}

.services-card__more {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #a2a2a2;
  text-transform: uppercase;

  &:hover {
    &::before {
      width: 100%;
    }
  }

  &::before {
    @include pseudo;
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 50px;
    height: 2px;
    background: #1d69c9;
    transition: all 0.3s;
  }
}
