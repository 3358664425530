.tabs__content {
  display: none;

  &--active {
    display: block;
  }
}

.tabs__list {
  @include list-reset;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #dde3f9;
}

.tabs__item {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-family: $second-font;

  color: #39393a;
  letter-spacing: 0.03em;
  /* identical to box height */
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &--active {
    color: #1d69c9;
    border-color: #1d69c9;
  }
}

@media (min-width: 400px) {
  .tabs__list {
    justify-content: flex-start;
    margin-bottom: 45px;
  }

  .tabs__item {
    margin-right: 49px;
  }
}
