.tag {
  &:not(:last-child) {
    margin-right: 16px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 8px 25px;
    font-size: 14px;
    line-height: 17px;
    font-family: $second-font;
    color: #3e3e3e;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    white-space: nowrap;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
  }

  &--active {
    a {
      color: #1d69c9;
      border-color: #1d69c9;
    }
  }
}

@media (min-width: 1200px) {
  .tag {
    margin: 8px;

    &:not(:last-child) {
      margin: 8px;
    }
  }
}
