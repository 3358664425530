.project-card {
  padding: 30px;
  //margin: 2px;
  border: 1px solid #cbcbdd;
  box-shadow: none;
  transition: all 0.3s;

  &:hover {
    border-color: #cbcbdd;
    //box-shadow: 0px 0px 4px #CBCFD4;
  }
}

.project-card__img {
  display: block;
  margin-top: -30px;
  margin-right: -30px;
  margin-bottom: 35px;
  margin-left: -30px;

  picture {
    display: block;
  }

  img {
    width: 100%;
  }
}

.project-card__title {
  display: block;
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  font-family: $second-font;
  color: #181818;
  /* identical to box height, or 132% */

  letter-spacing: 0.03em;
}


.project-card__desc {
  font-size: 15px;
  line-height: 23px;
  color: #858c92;
}

.project-card__more {
  display: block;
  margin-top: 25px;
  padding-top: 25px;
  font-size: 15px;
  line-height: 20px;
  color: #a2a2a2;
  text-transform: uppercase;
  border-top: 1px solid #ededed;
  transition: all 0.3s;

  &:hover {
    color: #1d69c9;
    border-color: #1d69c9;
  }
}

@media (min-width: 1200px) {
  .project-card {
    border-color: transparent;
  }
}
