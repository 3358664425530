.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #eff2f5;
}

.header__search {
  display: none;
}

.header__phone {
  display: none;
}

.header__callback {
  display: none;
}


@media (min-width: 1200px) {
  .header {
    @include page-wrapper;
    justify-content: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header__logo {
    width: 115px;
    margin-right: 30px;
  }

  .header__search {
    display: block;
    width: 21px;
    height: 21px;
    margin-left: 27px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .header__phone {
    display: flex;
    align-items: center;
    margin-left: 33px;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
    letter-spacing: 0.03em;

    &::before {
      @include pseudo;
      width: 20px;
      height: 20px;
      margin-right: 16px;
      background-image: url("../../img/icon__phone.svg");
    }
  }

  .header__callback {
    display: flex;
    height: 45px;
    margin-left: auto;
    padding: 0 20px;
  }
}
