.text-block {
  margin-bottom: 65px;
  font-size: 15px;
  line-height: 24px;
  color: #000000;

  & + .main__controls {
    margin-top: -40px;
  }

  & + .main__callback {
    margin-top: -50px;
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232426;
  }

  img {
    display: block;
    border-radius: 3px;
  }

  img + h2 {
    margin-top: 40px;
  }

  p + img {
    margin-top: 20px;
  }

  ul {
    @include list-reset;
    margin-bottom: 20px;

    li {
      display: flex;
      align-items: flex-start;

      &::before {
        @include pseudo;
        width: 5px;
        min-width: 5px;
        height: 5px;
        margin-top: 10px;
        margin-right: 17px;
        background: #1d69c9;
        border-radius: 50%;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  p + h2 {
    margin-top: 40px;
  }

  img + p {
    margin-top: 30px;
  }

  p + p {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .text-block {
    & + .main__controls {
      margin-top: 0;
    }

    & + .main__callback {
      margin-top: 0;
    }
  }
}
