.navigation__button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 65px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1d69c9;
  background: #f8fafb;
  border: 1px solid #1d69c9;
  border-radius: 3px;

  &::before {
    @include pseudo;
    width: 15px;
    height: 15px;
    margin-right: 28px;
    background-image: url("../../img/icon__dots.svg");
  }
}

.navigation__inner {
  position: relative;
  display: none;
  box-sizing: border-box;
  margin-top: -65px;
  padding: 30px 25px;
  background: #f8fafb;
  border: 1px solid #1d69c9;
  border-radius: 3px;

  &--active {
    display: block;
  }
}

.navigation__close {
  position: absolute;
  top: 30px;
  right: 25px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  appearance: none;

  svg {
    width: 13px;
    height: 13px;
    fill: #1d69c9;
  }
}

.navigation__title {
  margin-bottom: 26px;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  color: #1d69c9;
}

.navigation__list {
  @include list-reset;
}

.navigation__item {
  &:not(:last-child) {
    border-bottom: 1px solid #dadce0;
  }

  a {
    display: block;
    padding: 15px 0;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
}
