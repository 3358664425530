.callback {
  box-sizing: border-box;
  padding: 40px 20px;
  font-family: $second-font;
  background: linear-gradient(90deg, #fafcff 0%, #f1f7ff 100%);
  border: 1px solid #cbcbdd;
  border-radius: 3px;
}

.callback__title {
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;
  text-align: center;
  color: #03183d;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.callback__desc {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #9ea2a8;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.callback__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b7b1b1;
}

.callback__input {
  width: 100%;
  height: 71px;
  margin-bottom: 20px;
  padding-left: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #03183d;
  background: transparent;
  border: 1px solid #1d69c9;
  border-radius: 3px;
}

.callback__button {
  width: 100%;
  height: 71px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: linear-gradient(180deg, #4f84eb 0%, #2861cf 100%);
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, #3480ea 0%, #1654b3 100%);
  }
}

@media (min-width: 1200px) {
  .callback {
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
    padding: 90px 60px 35px 60px;
  }

  .callback__right {
    flex-grow: 1;
  }

  .callback__input {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .callback__button {
    margin-bottom: 0;
    margin-left: 15px;
  }

  .callback__form {
    display: flex;
    margin-bottom: 26px;
  }

  .callback__text {
    text-align: left;
  }

  .callback__content {
    margin-right: 55px;
  }

  .callback__desc {
    margin-bottom: 0;
    text-align: left;
  }

  .callback__title {
    font-size: 30px;
    line-height: 38px;
    text-align: left;
  }
}
